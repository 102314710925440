import * as React from 'react';
import Seo from '../components/seo';

const fAQPage = () => (
  <div className="container mx-auto py-6 px-4">
    <Seo title="Datenschutzerklärung" description="Datenschutzerklärung" />
    <div className="prose">
      <h1 className="mb-0">Datenschutzerklärung</h1>
      <h2>Datenverwendung und Weitergabe</h2>
      <p>
        Die personenbezogenen Daten, die Sie uns über diese Webseite oder per
        E-Mail mitteilen (Name, Adresse und E-Mail-Adresse), werden nur zur
        Korrespondenz (um Informationen im Rahmen der Veranstaltung mit Ihnen zu
        teilen) und nur für den Zweck verwendet, zu dem Sie uns die Daten zur
        Verfügung gestellt haben. Der angegebene Name im Zusammenhang mit Ihrer
        Anschrift und E-Mail Adresse wird für keine weiteren Zwecke, wie z.B.
        eigene Mailinglisten genutzt oder an Dritte weitergegeben.
      </p>
      <p>
        Ihre angegeben Adresse erscheint ohne Angabe von Namen und E-Mail
        Adresse auf einer online Karte bei Google Maps im Rahmen des
        Flohmarktes.
      </p>
      <p>Alle Daten werden nach der Veranstaltung gelöscht.</p>
      <p>
        Die Website verwendet keine Cookies, Google Analytiks Funktionen oder
        andere Hilfsmittel um Nutzerverhalten zu erfassen.
      </p>
      <p>Verantwortliche für die Datenverarbeitung ist Julia Hofmann.</p>
    </div>
  </div>
);

export default fAQPage;
